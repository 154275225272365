@import './assets/fonts/fontello/css/saf.css';

body {
  overflow-x: hidden !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#HW_badge_cont:nth-of-type(n + 3) {
  display: none;
}

#HW_badge {
  background: #0066f5 !important;
}

.flexi-top-toogle .flexi-button-center i.flexibull-left:before {
  font-family: 'saf';
  content: '\e8e0';
  font-size: 20px;
}

.flexi-table {
  overflow-x: visible !important;
}
@media print {
  .flexi-side-brand,
  .flexi-side-content {
    display: none;
  }
}
.noHover {
  background-color: #fafafa;
}
.noHover:hover {
  background-color: transparent;
}

.underline {
  background-repeat: repeat-x;
}

.underline--rule {
  background-image: url('./assets/underline.svg');
  background-position: 0 1.06em;
  background-size: contain;
}

.react-date-picker__calendar,
.react-daterange-picker__calendar {
  margin-top: -40px !important;
  margin-left: -3px !important;
  position: absolute !important;
}

.ReactCrop__image {
  display: block;
  max-width: 100%;
  -ms-touch-action: none;
  touch-action: none;
  height: 260px !important;
  left: 150px;
  margin: auto;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  width: 100% !important;
  margin: auto;
}

.react-joyride__tooltip {
  font-family: 'open sans', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px !important;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 20.7722px !important;
  left: 100.7722px !important;
  width: 200.75px !important;
  height: 200.75px !important;
}

.rc-h-48 {
  height: 8rem !important;
}

.rc-border-r-2 {
  border-right-width: 1px !important;
}

.rc-border-b-2 {
  border-bottom-width: 1px !important;
}

.rc-border-t-2 {
  border-top-width: 1px !important;
}

.rc-border-l-2 {
  border-left-width: 1px !important;
}

.step-listing {
  -webkit-column-count: 2; /* Chrome/Opera, Safari */
  -moz-column-count: 2; /* Mozilla Firefox */
  column-count: 3;

  /* Properties below are optional: */
  -webkit-column-gap: 50px; /* Chrome/Opera, Safari */
  -moz-column-gap: 50px; /* Mozilla Firefox */
  column-gap: 50px;

  -webkit-column-rule: 1px single grey; /* Chrome/Opera, Safari */
  -moz-column-rule: 1px single grey; /* Mozilla Firefox */
  column-rule: 1px single grey;
}

.center ul {
  display: flex;
  padding-inline-start: 0 !important;
  width: 150%;
}

.center ul li {
  font-size: 11px;
  color: #737373;
  margin: 0 10px;
  word-wrap: unset;
}

.center ul li.done {
  color: #2cda9d;
  list-style-type: none;
}

.center ul li.done::before {
  content: '\2713';
  margin-right: 3px;
}

.flexi-navigation-item a i {
  font-size: 15px;
}

.flexi-dropdown-list li span i {
  font-size: 14px;
}

.flexi-dropdown-list li i {
  font-size: 14px;
}

@media print {
  .hidden-print {
    display: none;
  }
}

.flexi-top-toogl .flexi-button {
  font-size: 24px;
}

.flexi-top-title {
  margin-left: 20px;
}
